@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
}

.rootForImage {
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: white;
  border-top: 1px solid var(--colorGrey100);
}

.price {
  /* Layout */
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0; */
  margin-bottom: 3px;
  text-align: end;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;
  width: 78%;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
  hyphenate-limit-chars: 10 5 5;
}

.subTitle {
  font-size: 13px;
  text-align: end;
  line-height: normal;
}

.rightFlex {
  display: flex;
  flex-direction: column;
  /* max-width: 69px; */
}

.vatColor {
  color: #707070;
  font-size: 11px;
  line-height: normal;
  text-align: end;
  font-weight: var(--fontWeightRegular);
  white-space:nowrap;
}

.subTitleUpperCase {
  font-size: 11px;
  line-height: normal;
  text-align: end;
  text-transform: uppercase;
  padding-top: 1px;
  margin-left: auto;
}

.subTitleUpperCaseRentSale {
  composes: subTitleUpperCase;
  hyphenate-limit-chars: 10 5 5;
}

.contentTextCard {
  display: flex;
  justify-content: space-between;
}

.displayContent {
  /* width: 78%; */
  padding-right: 10px;
}

.dateStyle {
  color: var(--TextGreySmall-W, #737373);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 145.455% */
  letter-spacing: -0.2px;
}